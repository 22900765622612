import React from 'react';

const PreviewEmail = ({ companyName, link, buttonText, subtitle, customMessage, message, questionCount, year, companyRep, companyEmail, applicant }) => {
  return (
    <>
      <head>
        <meta charset="UTF-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="x-apple-disable-message-reformatting" />
        <meta content="target-densitydpi=device-dpi" name="viewport" />
        <meta content="true" name="HandheldFriendly" />
        <meta content="width=device-width" name="viewport" />
        <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no" />
        <style type="text/css">
          {`
          table {
            border-collapse: separate;
            table-layout: fixed;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
          }
          table td {
            border-collapse: collapse;
          }
          .ExternalClass {
            width: 100%;
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%;
          }
          body, a, li, p, h1, h2, h3 {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }
          html {
            -webkit-text-size-adjust: none !important;
          }
          body, #innerTable {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          #innerTable img+div {
            display: none !important;
          }
          img {
            margin: 0;
            padding: 0;
            -ms-interpolation-mode: bicubic;
          }
          h1, h2, h3, p, a {
            line-height: inherit;
            overflow-wrap: normal;
            white-space: normal;
            word-break: break-word;
          }
          a {
            text-decoration: none;
          }
          h1, h2, h3, p {
            min-width: 100%!important;
            width: 100%!important;
            max-width: 100%!important;
            display: inline-block!important;
            border: 0;
            padding: 0;
            margin: 0;
          }
          a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
          }
          u + #body a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
          a[href^="mailto"],
          a[href^="tel"],
          a[href^="sms"] {
            color: inherit;
            text-decoration: none;
          }
          img, p {
            margin: 0;
            font-family: Fira Sans, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
            line-height: 27px;
            font-weight: 400;
            font-style: normal;
            font-size: 18px;
            text-decoration: none;
            letter-spacing: 0;
            color: #bdbdbd;
            text-align: left;
          }
          h1 {
            font-family: Fira Sans, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
            line-height: 33px;
            font-weight: 700;
            font-size: 25px;
            color: #dcff93;
            text-align: left;
          }
          h2 {
            font-family: Fira Sans, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
            line-height: 30px;
            font-weight: 700;
            font-size: 24px;
            color: #ddff94;
            text-align: left;
          }
          h3 {
            font-family: Lato, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
            line-height: 26px;
            font-weight: 700;
            font-size: 20px;
            color: #ddff94;
            text-align: left;
          }
          `}
        </style>
      </head>
      <body style={{ backgroundColor: "#21828D", margin: 0, padding: 0, borderRadius:5 }}>
        <div style={{ backgroundColor: "#21828D", borderRadius:5 }}>
          <table role="presentation" width="100%" align="center">
            <tr>
              <td style={{ backgroundColor: "#21828D" }} align="center">
                <table role="presentation"  width="100%" align="center">
                  <tr>
                    <td style={{ width: "460px", padding: "20px 10px" }}>
                      <div style={{ width: "100%", textAlign: "center", verticalAlign: "top" }}>
                        <div style={{ textAlign: "initial", width: "100%", maxWidth: "600px" }}>
                          <table role="presentation" width="100%">
                            <tr>
                              <td>
                                <table role="presentation" width="100%">
                                  <tr>
                                    <td style={{ width: "200px" }}>
                                      <img
                                        src="https://helpdeskforhr.com/wp-content/uploads/2024/09/rc-logo-dark.png"
                                        alt=""
                                        width="200"
                                        height="46.25"
                                        style={{ display: "block", border: 0, height: "auto", width: "205px" }}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ width: "460px" }}>
                <div style={{ width: "100%", textAlign: "center", verticalAlign: "top" , justifyContent:'center'}}>
                  <div style={{ textAlign: "initial", width: "100%",   }}>
                    <table role="presentation" width="100%">
                      <tr>
                        <td style={{ backgroundColor: "#FFFFFF", padding: "30px", borderRadius: "0 0 8px 8px" }}>
                          <h2 style={{ color: "#21828D" }}>{`${companyName} has requested a reference check for ${applicant}`}</h2>
                          <p style={{ color: "#333333", lineHeight: "28px", fontWeight: 500, marginTop:20 }}>{`Your previous employee,`} <span><b>{`${applicant},`}</b></span> {`has applied for a position with ${companyName} and has given authorization to request a reference from you as a previous employer.`}</p>

                          {customMessage ?  <p style={{ color: "#333333", lineHeight: "28px", fontWeight: 500, marginTop:20 }}>{message}</p> : ''}
                         
                          <p style={{ color: "#333333", lineHeight: "28px", fontWeight: 500, marginTop:20 }}>{`At your earliest convenience, I would greatly appreciate your response to the`} <span><b>{`${questionCount}`}</b></span> {`questions provided below. You may view a copy of`} <span><b>{`${applicant.split(' ')[0]}'s,`}</b></span> {`reference authorization (attached to this email) if one was provided.`}</p>
                          <p style={{ color: "#333333", lineHeight: "28px", fontWeight: 500, marginTop:20 }}>{`Please feel free to reach out if you have any questions.`}</p>
                          <p style={{ color: "#333333", lineHeight: "28px", fontWeight: 500, marginTop:20 }}>{`Thank you in advance for your assistance.`}</p>

                          <p style={{ color: "#333333", fontWeight: 'bold', marginTop:20 }}>{`${companyRep}`}</p>
                          <p style={{ color: "#333333", fontWeight: 'bold' }}>{`${companyName}`}</p>
                          <p style={{ color: "#333333", fontWeight: 'bold' }}>{`${companyEmail}`}</p>

                          <a
                            // href={link}
                            style={{
                              display: "block",
                              backgroundColor: "#21828D",
                              color: "#FFFFFF",
                              lineHeight: "50px",
                              textAlign: "center",
                              borderRadius: "14px",
                              fontSize: "14px",
                              fontWeight: 600,
                              textDecoration: "none",
                              margin: "20px 0",
                            }}
                          >
                            {'CLICK HERE TO ANSWER THE QUESTIONS'}
                          </a>
                          <p style={{ color: "#545454", fontSize: "16px" }}>{'link'}</p>
                          <p style={{ color: "#878787", fontSize: "14px" }}>© {year} Andere HR Inc. All Rights Reserved</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </>
  );
};

export default PreviewEmail;
