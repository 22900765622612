// LoaderModal.js
import React from 'react';
import { Modal, Backdrop, CircularProgress, Box } from '@mui/material';
import logo from '../../assets/images/rc-logo-dark.png'
const styles = {
  modal:{
    display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  },
  container:{
    top:0,
    bottom:0,
    left: 0,
    right:0,
    position:'absolute',
    backgroundColor: '#21828D',
    padding: 0,
    borderRadius: 0,
    display: 'flex',
    justifyContent:'center',
    // paddingTop:'25%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo:{
    maxWidth: '50%',
    maxHeight: 85, // Adjust the maximum height as needed
    marginBottom: 5,
  }
}

const Loader = ({ open, title}) => {


  return (
    <Modal

      sx={{ display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',}}
      open={open}
      closeAfterTransition
    
    >
     
      <Box sx={styles.container}>
        <img style={styles.logo} src={logo} alt="logo" />
        {title}
      <CircularProgress sx={{color:'white'}} />


      </Box>
    </Modal>
  );
};

export default Loader;
