import './App.css';
import Home from './screens/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { LoadingUser } from './components/LoadingUser';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API } from './classes/config/controller';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Preview } from './screens/Preview';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ReferencerWorksheet } from './screens/ReferencerWorksheet';
import { ReferenceDetails } from './screens/ReferenceDetails';
import { Viewer } from './screens/Viewer';
import { WebViewer } from './screens/WebViewer';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Loader from './components/Loader';
import { Redirect } from './screens/Redirect';
import { ArchivedReferenceDetails } from './screens/ArchivedReferenceDetails';
import './assets/css/image-card.css'
function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false); // Default to false

  const wakeUpServer = async () => {
    try {
      let resp = await axios.get(API);
      console.log(resp);
    } catch (error) {
      console.error('Error waking up the server:', error);
    }
  };

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie && userCookie !== 'null') {
      wakeUpServer();
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    setLoading(false); // Set loading to false after checking
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    if(loggedIn){
      return (
        <Routes>
          {/* Public Routes */}
          <Route path="/viewer" element={<Viewer />} />
          <Route path="/referencer-worksheet" element={<ReferencerWorksheet />} />
          <Route path="/web-viewer" element={<WebViewer />} />
    
          {/* Protected Routes */}
          <Route path="/" element={loggedIn ? <Home /> : <Navigate to="http://www.helpdeskforhr.com/login/" />} />
          <Route path="/preview" element={loggedIn ? <Preview /> : <Navigate to="http://www.helpdeskforhr.com/login/" />} />
          <Route path="/reference-details" element={loggedIn ? <ReferenceDetails /> : <Navigate to="http://www.helpdeskforhr.com/login/" />} />
          <Route path="/archived-reference-details" element={loggedIn ? <ArchivedReferenceDetails /> : <Navigate to="http://www.helpdeskforhr.com/login/" />} />

          {/* Fallback Route for 404 */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    } else{
      return(  
        <Routes>
               <Route path="/" element={<Redirect />} />

      <Route path="/viewer" element={<Viewer />} />
        <Route path="/referencer-worksheet" element={<ReferencerWorksheet />} />
        <Route path="/web-viewer" element={<WebViewer />} />
        </Routes>
      )
    }
  }

  
}

export default App;
