import React, { useEffect, useState } from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Button, Grid, IconButton, Link, Paper, TextField, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Container } from "react-bootstrap";
import hdhrIcon from '../assets/hdhr-icon.png';
import appIcon from '../assets/images/rc-logo-dark.png';
import axios from "axios";
import { API, link } from "../classes/config/controller";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { Download } from "@mui/icons-material";
import { GenerateDocument } from "../classes/GenerateDocx";
import ToolTipButton from "../components/ToolTipButton";
import { useTheme } from '@mui/material/styles';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...(open && {}),
}));

function Copyright(props) {
  return (
    <div style={{ textAlign: 'center', marginTop: '25px' }}>
      <img src={hdhrIcon} width="75px" />
      <br></br>
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com/">
          HelpDesk for HR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </div>
  );
}

export const Viewer = () => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const location = useLocation();  // Access the current location object
  const { referenceCheckId, id, referencerId, object, docName, archived } = location.state;

  const theme = useTheme(); // Get the current theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the device is mobile

  useEffect(() => {
    const fetchReferenceData = async () => {
      console.log(archived)
      try {
        const response = await axios.post(`${API}rc/get-reference`, { id, referenceCheckId, referencerId, archived });

        if (response.status === 200) {
          console.log('Reference data retrieved successfully:', response.data);
          setData(response.data);
          setLoading(false);
        } else {
          console.error('Error fetching reference data:', response.data.message);
          window.alert('Something went wrong, please try again later');
        }
      } catch (error) {
        console.error('Error during fetching reference:', error.response?.data?.message || error.message);
        window.alert('Something went wrong, please try again later');
      }
    };

    fetchReferenceData();
  }, [update]);

  if (loading) {
    return (
      <Loader title={<Typography className="mb-2" color="white"><b>{loadingTitle}</b></Typography>} open={true} />
    );
  } else {
    return (
      <>
        <AppBar position="absolute">
          <Toolbar
            sx={{
              backgroundColor: '#21828D',
              pr: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <img src={appIcon} height={isMobile ? 40 : 50} />
            <ToolTipButton
              onClick={() => GenerateDocument(object, docName)}
              Title="Download Docx"
              sx={{ position: 'absolute', right: 15 }}
              Icon={<Download htmlColor="white" />}
            />
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: '#eef2f6',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: isMobile ? 2 : 10, // Adjust padding for mobile
            pt: 10 // Additional padding to compensate for the AppBar
          }}
        >
          <Toolbar>
            <Grid container spacing={isMobile ? 2 : 4}>
              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Applicant Name:</b></Typography>
                <Typography>{data?.applicant?.firstName} {data?.applicant?.lastName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Request sent to:</b></Typography>
                <Typography>{data.reference.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Reference requested by:</b></Typography>
                <Typography>{data?.company?.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Contact:</b></Typography>
                <Typography>{data.company.companyRep} - {data?.company?.companyEmail}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <hr />
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Paper sx={{ padding: isMobile ? 2 : 5, paddingTop: 1, marginTop: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {data?.reference.worksheet?.questions?.map((i, index) => (
                    <Box className="mt-4" key={index}>
                      <Typography sx={{ mb: 1 }}><b>{index + 1}. {i.question}</b></Typography>
                      <Typography>{i.response}</Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Paper>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </>
    );
  }
};
