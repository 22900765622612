import React, { useState } from "react";
import {TextareaAutosize, Button, Modal, Box, Typography, TextField, Paper, IconButton, Grid, Icon } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { v4 as uuidv4 } from 'uuid';
import { Delete, Edit, EditOffOutlined, Menu } from "@mui/icons-material";
import ToolTipButton from "../ToolTipButton";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "axios";
import { API } from "../../classes/config/controller";
import { addWorksheet, user } from "../../classes/GlobalFunctions";

// Rename this component to avoid conflict
const SortableItemComponent = ({ id, question, onClickDelete}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 8,
    backgroundColor: 'white',
    border:'grey 1px solid',
    borderRadius:5
  };

  return (
    <Grid ref={setNodeRef} style={style} {...attributes} {...listeners} container>
    <Grid item xs={1} md={1}
     container
    alignItems="center" // Centers the delete button vertically
    justifyContent="center" // Centers the delete button horizontally

    >
    <Menu htmlColor="dodgerBlue"/>
    </Grid>
    <Grid
      container
      alignItems="center" // Centers the delete button vertically
    // Centers the delete button horizontally
    xs={11} md={11} item>
     <div >
      {question}
    </div>    
    </Grid>
    
    </Grid>
    
  );
};

export const EditWorksheetModal = ({ currentWorksheet, setCurrentWorksheet, update, setUpdate, handleClose, show, handleUpdate, working, setWorking }) => {
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [editing, setEditing] = useState(false);
  const [alignment, setAlignment] = React.useState('1');
  const [customQuestion, setCustomQuestion] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [questionNo, setQuestionNo] = React.useState('');

  const questions = [
    {
      id:0,
      label: `Custom Question`,
      value:`custom-82659`
    },
    {
        id:0,
        label: `What were the beginning and ending dates of the employees employment?`,
        value:`What were the beginning and ending dates of employees employment?`
      },
      {
        id:1,
        label:`What position did the employee hold in your organization?`,
        value:`What position did the employee hold in your organization?`
      }, 
      {
        id:2,
        label:`Is the employee eligible for rehire?`,
        value:`Is the employee eligible for rehire?`
      },
    {
        id:3,
        label:`Did the employee have any supervision responsibilities?`,
        value:`Did the employee have any supervision responsibilities?`
    },
    {
        id:4,
        label: 'How closely were you required to supervise the employee?',
        value:`How closely were you required to supervise the employee?`
    },
    {
        id:5,
        label: 'How willing was the employee to accept responsibility?',
        value:`How willing was the employee to accept responsibility?`
    },
    {
        id:6,
        label: 'What were some of the employee’s greatest strengths?',
        value:`What were some of the employee’s greatest strengths?`
    },
    {
        id:7,
        label: 'What were areas in which the employee needed more personal or professional development?',
        value:`What were areas in which the employee needed more personal or professional development?`
    },
    {
        id:8,
        label: 'What level of ownership would you say the employee took over the work assigned?',
        value:`What level of ownership would you say the employee took over the work assigned?`
    },
    {
        id:9,
        label: 'Could you rely on the employee to finish tasks in a timely manner?',
        value:`Could you rely on the employee to finish tasks in a timely manner?`
    },
    {
        id:10,
        label: 'Was the employee generally organized and prepared for tasks, deadlines, meetings, etc.?',
        value:`Was the employee generally organized and prepared for tasks, deadlines, meetings, etc.?`
    },
    {
        id:11,
        label: 'How well did the employee get along with other people?',
        value:`How well did the employee get along with other people?`
    },
    {
        id:12,
        label: 'Did the employee have issues with attendance?',
        value:`Did the employee have issues with attendance?`
    },
    {
        id:13,
        label: 'Why did the employee leave the company?',
        value:`Why did the employee leave the company?`
    },
    {
        id:14,
        label: 'What would you say is the employee’s level of ability with technology?',
        value:`What would you say is the employee’s level of ability with technology?`
    },
    {
        id:15,
        label: 'How did the employee handle conflict in the workplace?',
        value:`How did the employee handle conflict in the workplace?`
    },
    {
        id:16,
        label: 'Please tell me about a time when the employee was assigned a collaborative task or project. What were the strengths and weaknesses that the employee exhibited during the collaboration?',
        value:`Please tell me about a time when the employee was assigned a collaborative task or project. What were the strengths and weaknesses that the employee exhibited during the collaboration?`
    },
    {
        id:17,
        label: 'Did the employee learn new tasks or processes quickly or was there a struggle to grasp new concepts?',
        value:`Did the employee learn new tasks or processes quickly or was there a struggle to grasp new concepts?`
    },
    {
        id:18,
        label: 'What would you say was the greatest asset the employee offered to your organization?',
        value:`What would you say was the greatest asset the employee offered to your organization?`
    },
    {
        id:19,
        label: 'Was the employee written up or counseled for conduct issues during employment with your company?',
        value:`Was the employee written up or counseled for conduct issues during employment with your company?`
    },
    {
        id:20,
        label: 'Would you classify the employee as a goal setter, goal achiever, or goal-averse?',
        value:`Would you classify the employee as a goal setter, goal achiever, or goal-averse?`
    },
    {
        id:21,
        label: 'Tell me about a time when the employee failed at a task. How did they handle the failure and what was the result?',
        value:`Tell me about a time when the employee failed at a task. How did they handle the failure and what was the result?`
    },
    {
        id:22,
        label: 'When assigned any sort of company training would  you say the employee completed the courses in a timely manner or had to be encouraged to participate?',
        value:`When assigned any sort of company training would  you say the employee completed the courses in a timely manner or had to be encouraged to participate?`
    }
]

  const handleToggleEdit = (id) => {
    setCurrentWorksheet(prev => ({
      ...prev,
      questions: prev.questions.map((item) =>
        item.id === id
          ? {
              ...item,
              isEditing: !item.isEditing,
              // If we're exiting edit mode, save the editedQuestion to question
              question: item.isEditing ? item.editedQuestion : item.question,
              editedQuestion: item.isEditing ? item.editedQuestion : item.question
            }
          : item
      )
    }));
  };
  
  
  const handleQuestionEditChange = (id, newQuestion) => {
    setCurrentWorksheet(prev => ({
      ...prev,
      questions: prev.questions.map((item) =>
        item.id === id ? { ...item, editedQuestion: newQuestion } : item
      )
    }));
  };
  

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDelete = (id) => {
    setCurrentWorksheet(prev => ({
      ...prev,
      questions: prev.questions.filter((item) => item.id !== id)
    }));
  };

  const getQuestions = async () => {
    setWorking(true);
    let prompt = `Please help me create a list of reference check questions tailored for the ${jobTitle} role. The questions should focus on assessing the candidate's key competencies, experience, and overall fit for the position. Please include questions that will help uncover any potential red flags and insights into the candidate's work ethic, teamwork, and problem-solving abilities. Please generate ${questionNo} questions. Return the questions in an object array in data only! The object should look like this: {"id": "some-id", "question":"question", "response":"empty string"}`;

    const response = await axios.post(API + 'api/create-document', { request: prompt });
    if (response !== 'error') {
      console.log(response.data);
      try {
        setCurrentWorksheet(prev => ({
          ...prev,
          questions: [...prev.questions, ...response.data]
        }));
        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.error('Error parsing JSON response:', error);
        alert('Something went wrong please try again.');
        return;
      }
    } else {
      alert('Something went wrong please try again.');
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setCurrentWorksheet((prev) => {
        const oldIndex = prev.questions.findIndex(item => item.id === active.id);
        const newIndex = prev.questions.findIndex(item => item.id === over.id);
        return {
          ...prev,
          questions: arrayMove(prev.questions, oldIndex, newIndex)
        };
      });
    }
  };

  const handleQuestionAddition = () => {
    setCurrentWorksheet(prev => ({
      ...prev,
      questions: [
        ...prev.questions,
        { id: uuidv4(), question: currentQuestion.value, response: '' }
      ]
    }));
    setCurrentQuestion('');
  };

  const handleCustomQuestionAddition = () => {
    setCurrentWorksheet(prev => ({
      ...prev,
      questions: [
        ...prev.questions,
        { id: uuidv4(), question: customQuestion, response: '' }
      ]
    }));
    setCustomQuestion('');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Reference Worksheet
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <Form.Label><b>Step One:  </b>Add Worksheet Title</Form.Label>
            <TextField
              label="Worksheet Title"
              variant="outlined"
              value={currentWorksheet.worksheetTitle}
              onChange={(e) =>
                setCurrentWorksheet(prev => ({ ...prev, worksheetTitle: e.target.value }))
              }
              fullWidth
            />
            <hr />
            <Form.Label><b>Step Two:  </b>Add Questions</Form.Label>
            <br />
            <ToggleButtonGroup
              fullWidth
              className="mb-3"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="1">Question Bank</ToggleButton>
              <ToggleButton value="2">Question Writer</ToggleButton>
            </ToggleButtonGroup>

            {alignment === '1' && (
              <>
                <InputGroup>
                  <Autocomplete
                    sx={{ width: '80%' }}
                    value={currentQuestion || null}
                    onChange={(event, newValue) => setCurrentQuestion(newValue)}
                    options={questions}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    renderInput={(params) => (
                      <TextField {...params} label="Select a Question" variant="outlined" />
                    )}
                  />
                  <Button
                    disabled={currentQuestion.value === 'custom-82659'}
                    sx={{ width: '20%' }}
                    onClick={handleQuestionAddition}
                    variant="contained"
                  >
                    Add Question
                  </Button>
                </InputGroup>
                {currentQuestion.value === 'custom-82659' && (
                  <>
                    <TextField
                      className="mt-4"
                      label={'Enter Custom Question'}
                      value={customQuestion}
                      onChange={(e) => setCustomQuestion(e.target.value)}
                      fullWidth
                      multiline
                      rows={6}
                      variant="outlined"
                    />
                    <Button
                      onClick={handleCustomQuestionAddition}
                      variant="contained"
                      className="mt-3"
                      fullWidth
                    >
                      Add Question
                    </Button>
                  </>
                )}
              </>
            )}

            {/* Generate questions from question writer */}
            {alignment === '2' && (
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} item>
                    <Typography><b>Job Title</b></Typography>
                    <TextField
                      fullWidth
                      onChange={(e) => setJobTitle(e.target.value)}
                      value={jobTitle}
                    />
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Typography><b>Question Count</b></Typography>
                    <TextField
                      type="number"
                      fullWidth
                      onChange={(e) => {
                        const value = Math.min(10, e.target.value);
                        setQuestionNo(value);
                      }}
                      value={questionNo}
                      inputProps={{ min: 1, max: 10 }}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Button variant="contained" fullWidth onClick={getQuestions}>
                      Generate Questions <Spinner size="sm" style={{ display: working ? 'inline-block' : 'none', marginLeft: 10 }} />
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            <hr />
            <Typography variant="button"><b>Worksheet Preview</b></Typography>
            <br />
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
              <SortableContext items={currentWorksheet.questions} strategy={verticalListSortingStrategy}>
                <Paper className="p-2" style={{ backgroundColor: 'white', marginTop: '16px' }}>
                  {/* Worksheet Title */}
                  <Grid container className="p-2" spacing={2}>
                    <Grid item xs={10} md={6}>
                      <Typography variant="h6">{currentWorksheet.worksheetTitle}</Typography>
                    </Grid>
                    <Grid item xs={2} md={6}>
                      <Box sx={{ textAlign: 'end' }}>
                        {editing && <ToolTipButton onClick={() => setEditing(false)} Title={<Typography>Back to question arrangement</Typography>} Icon={<EditOffOutlined />} />}
                        {!editing && <ToolTipButton onClick={() => setEditing(true)} Title={<Typography><b>Edit Questions</b><br />Click here to remove questions you may not want.</Typography>} Icon={<Edit />} />}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Questions List */}
                  {!editing && (
                    <Grid container>
                      {currentWorksheet.questions.map((item) => (
                        <SortableItemComponent key={item.id} id={item.id} question={item.question} onClickDelete={() => handleDelete(item.id)} />
                      ))}
                    </Grid>
                  )}

                  {editing && (
                    <>
                      {currentWorksheet.questions.map((item) => (
  <Grid
    key={item.id}
    sx={{ marginTop: 1, marginBottom: 1, padding: 1, border: 'grey 1px solid', borderRadius: 1 }}
    container
  >
    <Grid container item sx={1} md={1} alignItems="center" justifyContent="center">
      <IconButton onClick={() => handleDelete(item.id)}><Delete htmlColor="tomato" /></IconButton>
    </Grid>

    <Grid container item sx={10} md={10} alignItems="center">
      {item.isEditing ? (
        <TextField
          fullWidth
          maxRows={2}
          value={item.editedQuestion || item.question}
          onChange={(e) => handleQuestionEditChange(item.id, e.target.value)}
          variant="outlined"
        />
      ) : (
        <Typography>{item.question}</Typography>
      )}
    </Grid>

    <Grid container item sx={1} md={1} alignItems="center" justifyContent="center">
      <IconButton onClick={() => handleToggleEdit(item.id)}>
        {item.isEditing ? <EditOffOutlined htmlColor="green" /> : <Edit htmlColor="dodgerBlue" />}
      </IconButton>
    </Grid>
  </Grid>
))}

                    </>
                  )}
                </Paper>
              </SortableContext>
            </DndContext>
          </Box>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={handleClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <Button
          onClick={handleUpdate}
          variant="contained">
            Save Changes <Spinner size="sm" style={{ display: working ? 'inline-block' : 'none', marginLeft: 10 }} />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

