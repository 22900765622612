import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, CircularProgress, IconButton, Grid } from "@mui/material";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Cookies from "js-cookie";
import { API, SaveQuestionnaire, UpdateJobPost } from "../classes/config/controller";
import { formatDate, getOrCreateProfile } from "../classes/GlobalFunctions";

import { ReferenceSetupModal } from "../components/Modals/ReferenceSetupModal";
import { Spinner } from "react-bootstrap";
import ToolTipButton from "../components/ToolTipButton";
import { Archive, Delete, InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "../components/SearchBar";
export const References = ()=>{
  const navigate = useNavigate();
const [user, setUser] = useState(Cookies.get('user'))
const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [update, setUpdate] = useState(false)
const [show, setShow] = useState(false);
const [id, setId] = useState('false');
const [searchBar, setSearchBar] = useState('');

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


const deleteReference = async (referenceId) => {
  const conf = window.confirm('Are you sure you would like to do this?')
  if(conf){
    
  try {
    const response = await axios.delete(`${API}rc/reference-checks/${referenceId}`, {
      data: { userEmail: user }  // Pass the user's email to identify the profile
    });
    console.log('Reference deleted:', response.data);
    setUpdate(!update);  // Trigger re-fetch of data after deletion
  } catch (error) {
    console.error('Error deleting reference:', error);
  }

}
};

const archiveReference = async (referenceCheckId) => {
  // Confirm the action with the user
  const conf = window.confirm('Are you sure you would like to archive this reference? This action cannot be undone.');
  
  if (conf) {
    try {
      // Make the POST request to the archive endpoint
      const response = await axios.post(`${API}rc/archive-reference`, {
        id: id,  // Assuming 'id' is the profile ID, make sure it's defined in your component
        referenceCheckId: referenceCheckId  // Use 'referenceCheckId' instead of 'referenceId' to match the server-side parameter name
      });

      console.log('Reference archived:', response.data);
      
      // Trigger re-fetch of data after the archive operation
      setUpdate(!update);  // 'setUpdate' should be defined in your component
    } catch (error) {
      console.error('Error archiving reference:', error);
      window.alert('An error occurred while archiving the reference. Please try again.');
    }
  }
};


useEffect(()=>{
    
    getOrCreateProfile(user)
    .then(profile => {
        console.log('Profile data:', profile);
        setId(profile._id);
        setData(profile)
        setLoading(false)
        // You can now use the profile data in your frontend application
    });

},[update])

if(loading){
  return( <Box sx={{textAlign:'center'}}><Spinner/></Box>  )
} else {
        
    return (
        <Box sx={{padding:1, margin:1}}>
               
      <Grid sx={{marginBottom:2}} container>
      <Grid xs={12} md={8} item></Grid>
        <Grid sx={{justifyContent:'center', alignSelf:'center', float:'right'}} xs={12} md={4} item>
        <Button onClick={handleShow} sx={{backgroundColor:'#EDAF00', width:'100%',fontWeight:'bold'}} variant="contained">+ Create Reference</Button>

        </Grid>
      </Grid>
      <Grid sx={{marginBottom:2}} container>
        <Grid xs={12} md={12} item>
         <SearchBar
         label="Search Applicant By Name"
         value={searchBar}
         onChange={(e)=> setSearchBar(e.target.value)}
         />
        </Grid>
     
      </Grid>
        
        <Paper sx={{padding:1}}>

             <Table hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Applicants</b></Typography></th>
          <th><Typography variant="button"><b>Date Created</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Completion Status</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody>
          {data.referenceChecker?.referenceChecks?.filter(i =>
          (i.applicant.firstName).toLowerCase().includes(searchBar.toLowerCase()) ||
          (i.applicant.lastName).toLowerCase().includes(searchBar.toLowerCase())
        ).map(i=>{
            return(
                <tr key={i.id}>
        
          <td style={{paddingTop:'15px'}}><span >{i.applicant.firstName + ' ' +i.applicant.lastName}</span> </td>
          <td style={{paddingTop:'15px'}}>{formatDate(i.dateCreated)}</td>
          <td style={{paddingTop:'15px',textAlign:'center'}}>{i.references.filter(i=> (i.status === 'complete')).length}/{i.references.length}</td>
          <td style={{textAlign:'center'}}>
            <ToolTipButton
            Title="Applicant's Reference Details"
            Icon={<InfoOutlined htmlColor="dodgerBlue"/>}
            onClick={() => navigate('/reference-details', {
              state: { 
                id:id,
                referenceCheckId: i.id,        // Pass the reference check ID
              }
            })}          
            />
            <ToolTipButton
            Title="Archive Reference"
            Icon={<Archive htmlColor="dodgerBlue"/>}
            onClick={() => archiveReference(i.id)}            
            />
            <ToolTipButton
            Title="Delete"
            Icon={<Delete htmlColor="tomato"/>}
            onClick={() => deleteReference(i.id)}            
            />
           </td>
        </tr>
            )
          })}
      </tbody>
    </Table>
        </Paper>
        <ReferenceSetupModal id={id} worksheets={data.referenceChecker.worksheets} companies={data.referenceChecker.userCompanies} handleClose={handleClose} handleCloseAndSend={()=>{handleClose(); setUpdate()}} show={show}/>
        </Box>
    )
}
}