import React, { useEffect, useState } from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Button, Grid, IconButton, Link, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Container } from "react-bootstrap";
import hdhrIcon from '../assets/hdhr-icon.png'
import appIcon from '../assets/images/rc-logo-dark.png'


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    
    
    ...(open && {
      
      
    }),
  }));

  function Copyright(props) {
    return (
      <div style={{textAlign:'center', marginTop:'25px'}}>
        <img src={hdhrIcon} width="75px"/>
        <br></br>
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
       
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com/">
          HelpDesk for HR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </div>
    );
  }


export const Preview = ()=> {
const [data, setData] = useState(null);


useEffect(()=>{
   let userData = sessionStorage.getItem('data') 
   console.log(JSON.parse(userData))
   setData(JSON.parse(userData))
},[])
    const worksheet = {
        questions:[
            {
            question:'This is Question One?'
        },
        {
            question:'This is Question One?'
        },
        {
            question:'This is Question One?'
        },
        {
            question:'This is Question One?'
        }
    ]
    }
    return(
        <>
        <AppBar position="absolute">
          <Toolbar
            sx={{
               
            backgroundColor:'#21828D',
           
       
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <img src={appIcon} height={50}/>
            <Typography sx={{marginLeft:2}}><b>NOTE:</b> This preview is meant for review purposes only.</Typography>

          </Toolbar>
        </AppBar>
       
          <Box
          component="main"
          sx={{
            backgroundColor: '#eef2f6',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding:10
          }}
        >

          <Toolbar>
          <Grid container>
              <Grid sx={{ mb: 2 }} item xs={12} md={6}>
                <Typography variant="button"><b>Applicant Name:</b></Typography>
                <Typography>{data?.applicant?.firstName} {data?.applicant?.lastName}</Typography>

              </Grid>

              <Grid sx={{ mb: 2 }} item xs={12} md={6}>
              <Typography variant="button"><b>Request sent to:</b></Typography>
              <Typography>[Company Name]</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
              <Typography variant="button"><b>Reference requested by:</b></Typography>
              <Typography>{data?.company?.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
              <Typography variant="button"><b>Contact:</b></Typography>
              <Typography>{data?.company?.companyEmail}</Typography>
              </Grid>

            </Grid>
          </Toolbar>
          <hr></hr>
          <Container  maxWidth="lg" sx={{ mb: 4 }}>
          <Typography><b>Please answer the following questions and click Submit at the bottom of the page. Thank you in advance for your time and consideration.</b></Typography>

            <Paper sx={{padding:5, paddingTop:1, marginTop:2}}>
            <Grid container spacing={3}>
             
              {/* Recent Orders */}
              <Grid item xs={12}>
              {data?.worksheet?.questions?.map((i,index)=>{
                    return (
                        <Box className="mt-4">
                            <Typography><b>{index + 1}. {i.question}</b></Typography>
                            <TextField
                              multiline
                              rows={4}
                              fullWidth
                              
                              onChange={(e) => console.log(e.target.value)}
                            />                        
                            </Box>
                    )
                })}
               
              </Grid>

              <Grid item xs={12}>
                <Button onClick={()=>window.alert('This is a preview.')} variant='contained'>Send Response</Button>
              </Grid>
            </Grid>

            </Paper>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
  </>
    )
}