import React, { useEffect, useState } from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Button, Grid, IconButton, Link, Paper, TextField, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Container } from "react-bootstrap";
import hdhrIcon from '../assets/hdhr-icon.png';
import appIcon from '../assets/images/rc-logo-dark.png';
import axios from "axios";
import { API, link } from "../classes/config/controller";
import { useLocation } from "react-router-dom"; 
import Loader from "../components/Loader";
import { Download } from "@mui/icons-material";
import { GenerateDocument } from "../classes/GenerateDocx";
import ToolTipButton from "../components/ToolTipButton";
import { useTheme } from '@mui/material/styles'; // Import useTheme

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...(open && {}),
}));

function Copyright(props) {
  return (
    <div style={{ textAlign: 'center', marginTop: '25px' }}>
      <img src={hdhrIcon} width="75px" />
      <br></br>
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com/">
          HelpDesk for HR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </div>
  );
}

export const WebViewer = () => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState('');
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dlObject, setdlObject] = useState(null);
  const [docName, setDocName] = useState('');
  const location = useLocation(); // Access the current location object

  const searchParams = new URLSearchParams(location.search); // Parse the URL query parameters

  // Extract query parameters from the URL
  const referenceCheckId = searchParams.get('b');
  const id = searchParams.get('a');
  const referencerId = searchParams.get('c');

  const theme = useTheme(); // Get the current theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the device is mobile

  useEffect(() => {
    // Fetch reference data only if all parameters are present
    if (id && referenceCheckId && referencerId) {
      const fetchReferenceData = async () => {
        try {
          const response = await axios.post(`${API}rc/get-reference-web-viewer`, { id, referenceCheckId, referencerId });
      
          if (response.status === 200) {
            console.log('Reference data retrieved successfully:', response.data);
            setData(response.data);
            setdlObject({
              applicant: `${response.data?.applicant?.firstName} ${response.data?.applicant?.lastName}`,
              companyName: response.data.company.companyName,
              worksheet: response.data.reference.worksheet
            });
            setDocName(`${response.data?.applicant?.firstName} ${response.data?.applicant?.lastName}'s reference from ${response.data.company.companyName}`);
      
            setLoading(false);
          } else {
            console.error('Error fetching reference data:', response.data.message);
            window.alert('Something went wrong. Please try again later.');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message;
          
          if (errorMessage === 'Reference not found.') {
            // Display the alert and close the page if "Reference not found"
            window.alert('The reference worksheet is no longer available. You can close this page.');
            window.close(); // Close the window
          } else {
            console.error('Error during fetching reference:', errorMessage);
            window.alert('Something went wrong. Please try again later.');
          }
        }
      };
      

      fetchReferenceData();
    } else {
      // If parameters are missing, redirect to an error page or show an error message
      window.alert('Invalid or missing parameters. Please check the URL.');
    }
  }, [update]);

  if (loading) {
    return (
      <Loader title={<Typography className="mb-2" color="white"><b>{loadingTitle}</b></Typography>} open={true} />
    );
  } else {
    return (
      <>
        <AppBar position="absolute">
          <Toolbar
            sx={{
              backgroundColor: '#21828D',
              pr: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <img src={appIcon} height={isMobile ? 40 : 50} />
            <ToolTipButton
              onClick={() => GenerateDocument(dlObject, docName)}
              Title="Download Docx"
              sx={{ position: 'absolute', right: 15 }}
              Icon={<Download htmlColor="white" />}
            />
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: '#eef2f6',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: isMobile ? 2 : 10, // Adjust padding for mobile
            pt: 10 // Additional padding to compensate for the AppBar
          }}
        >
          <Toolbar>
            <Grid container spacing={isMobile ? 2 : 4}>
              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Applicant Name:</b></Typography>
                <Typography>{data?.applicant?.firstName} {data?.applicant?.lastName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Request sent to:</b></Typography>
                <Typography>{data.reference.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Reference requested by:</b></Typography>
                <Typography>{data?.company?.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Contact:</b></Typography>
                <Typography>{data.company.companyRep} - {data?.company?.companyEmail}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <hr />
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Paper sx={{ padding: isMobile ? 2 : 5, paddingTop: 1, marginTop: 2 }}>
              <Grid container spacing={isMobile ? 2 : 3}>
                <Grid item xs={12}>
                  {data?.reference.worksheet?.questions?.map((i, index) => (
                    <Box className="mt-4" key={index}>
                      <Typography sx={{ mb: 1 }}><b>{index + 1}. {i.question}</b></Typography>
                      <Typography>{i.response}</Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Paper>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </>
    );
  }
};
