import { Button, Grid, Paper, Typography } from "@mui/material";
import Table from 'react-bootstrap/Table';
import React, { useEffect, useState } from "react";
import { CompanyInfo } from "../components/settings/CompanyInfo";
import { Box } from "@mui/system";
import { CompanyModal } from "../components/Modals/CompanyModal";
import { addUserCompany, deleteUserCompany, formatDate, getOrCreateProfile } from "../classes/GlobalFunctions";
import Cookies from "js-cookie";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import ToolTipButton from "../components/ToolTipButton";
import { Delete } from "@mui/icons-material";
import ImageCard from "../components/Cards/ImageCard";
import { SearchBar } from "../components/SearchBar";
import PDFViewer from "../components/Modals/PdfViewer";
import doc1 from '../classes/document-rc/resources/Best_Practices_for_Checking_References.pdf'


export const Resources = () =>{
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [update, setUpdate] = useState(false)
    const [user, setUser] = useState(Cookies.get('user'))
    const [loading, setLoading] = useState(true)
    const [working, setWorking] = useState(false)
    const [searchBar, setSearchBar] = useState('');
    const [currentData, setCurrentData] = useState({
        id: uuidv4(),
        companyName:'',
        companyRep:'',
        companyEmail:''
    })

    const resourcesDocuments = [
        {
            id: 0,
            title: 'Sample Employee Reference Authorization form',
            file: 'https://helpdeskforhr.com/wp-content/themes/thrive-theme-child/Reference-Checker/Candidate%20Reference%20Check%20Authorization.docx',
            description: 'A template for candidates to authorize reference checks. This is optional.',
            type: 'docx'
        },
        {
            id: 1,
            title: 'Reference Checking and Providing Protections By State',
            file: 'https://helpdeskforhr.com/wp-content/uploads/2024/09/REFERENCE-CHECKING-AND-PROVIDING-PROTECTIONS-BY-STATE.pdf',
            description: 'State-specific protections for checking and providing references.',
            type: 'pdf'
        },
        {
            id: 2,
            title: 'Legal Concerns When Checking References',
            file: 'https://helpdeskforhr.com/wp-content/uploads/2024/09/Legal-Concerns-When-Checking-References.pdf',
            description: 'Overview of legal considerations when conducting reference checks.',
            type: 'pdf'
        },
        {
            id: 3,
            title: 'Reference Checking FAQs',
            file: 'https://helpdeskforhr.com/wp-content/uploads/2024/09/Reference-Checking-FAQs.pdf',
            description: 'Frequently asked questions regarding the reference checking process.',
            type: 'pdf'
        },
        {
            id: 4,
            title: 'Reference Providing FAQs',
            file: 'https://helpdeskforhr.com/wp-content/uploads/2024/09/Reference-Providing-FAQs.pdf',
            description: 'Frequently asked questions about providing references for employees.',
            type: 'pdf'
        },
        {
            id: 5,
            title: 'Best Practices When Providing a Reference for a Former Employee',
            file: 'https://helpdeskforhr.com/wp-content/uploads/2024/09/Best-Practices-When-Providing-a-Reference-for-a-Former-Employee.pdf',
            description: 'Guidelines on how to provide references for former employees effectively.',
            type: 'pdf'
        },
        {
            id: 6,
            title: 'Best Practices for Checking References',
            file: doc1,
            description: 'Best practices and tips for conducting effective reference checks.',
            type: 'pdf'
        },
    ];
    

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [open, setOpen] = useState(false);

const handleOpenPDF = () => setOpen(true);
const handleClosePDF = () => setOpen(false);

const [pfd, setPdf] = useState('')
useEffect(()=>{
    console.log(user)
    getOrCreateProfile(user)
    .then(profile => {
        console.log('Profile data:', profile);
        setData(profile)
        setLoading(false)
        // You can now use the profile data in your frontend application
    });

},[update])

const saveCompany = ()=> {
    if(currentData.companyName === "" || currentData.companyNamee === "" || currentData.companyEmaile === ""){
        window.alert('Please fill out all the inputs.')
    } else {
        
   setWorking(true)
    addUserCompany(user, currentData)
        .then(message => {
            console.log('Success:', message);
            setCurrentData({
                id: uuidv4(),
                companyName:'',
                companyRep:'',
                companyEmail:''
            })
            handleClose();
            setUpdate(!update)
            setWorking(false)

            // You can update the UI or state here based on the success
        });

    }
}

const deleteCompany = (companyId) => {
   

    deleteUserCompany(user, companyId)
        .then(message => {
            console.log('Success:', message);
            setUpdate(!update)
            // You can update the UI or state here based on the success
        });
}
if(loading){
    return( <Box sx={{textAlign:'center'}}><Spinner/></Box>  )
} else {
    
    return (
        <>
        <Box className="m-4">
      <Typography sx={{fontSize:22}} variant="button"><b>Settings</b></Typography>

        <Paper className="p-2">
        <Grid className="p-2" spacing={2} container >
        <Grid item xs={10} md={6}>
        <Typography variant="h6">Company Information</Typography>
        <Typography variant='subtitle2'>You can add multiple companies if needed.</Typography>

        </Grid>
        <Grid item xs={2} md={6} >
        <Box sx={{textAlign:'end'}}>
       <Button sx={{backgroundColor:'#EDAF00', fontWeight:'bold'}} variant='contained' onClick={handleShow}>+ Add Company</Button>
        </Box>
        </Grid>

        <Grid  item xs={12} md={12}>
            <SearchBar
            label="Search Company"
            value={searchBar}
            onChange={(e)=> setSearchBar(e.target.value)}
            />
        </Grid>
        <Grid  item xs={12} md={12}>
        <Table  hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Company Name</b></Typography></th>
          <th><Typography variant="button"><b>Recruiter/Rep Name</b></Typography></th>
          <th><Typography variant="button"><b>Email Address</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody  >
          {data.referenceChecker?.userCompanies?.filter(i =>
          (i.companyRep).toLowerCase().includes(searchBar.toLowerCase()) ||
          (i.companyName).toLowerCase().includes(searchBar.toLowerCase())
        ).map(i=>{
            return(
        <tr key={i.id}>
          <td>{i.companyName}</td>
          <td>{i.companyRep}</td>
          <td>{i.companyEmail}</td>
          <td><ToolTipButton Title="Delete" Icon={<Delete htmlColor="tomato" onClick={()=>deleteCompany(i.id)}/>}/></td>
        </tr>
            )
          })}
      </tbody>
    </Table>
        </Grid>
        </Grid>
          </Paper>
        <Box className="mt-4">
        <Typography sx={{fontSize:22}} variant="button"><b>Resources</b></Typography>

        <Paper className="p-2">
        <Box 
        display="flex"
        flexWrap="wrap"
        justifyContent="space-around"
        alignItems="flex-start"
        gap={2} // Space between cards
      >
            {resourcesDocuments.map((i,index)=>{
                return(
                  
            <ImageCard 
            type={i.type}
            title={i.title} 
            file={i.file}
            description={i.description}
            />
           
                )
            })}
            </Box>
        </Paper>
        </Box>

        </Box>
        <CompanyModal spinner={working} handleData={saveCompany} setCompanyInfo={setCurrentData}  currentData={currentData} handleClose={handleClose} show={show}/>
        <PDFViewer open={open} handleClose={handleClosePDF} pdfLink={pfd} />

        </>
    )

}
}