import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { EmbedPDF } from '@simplepdf/react-embed-pdf';

const PDFModal = ({ open, handleClose }) => {
  const styles = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      width: '80vw',
      height: '90vh',
      backgroundColor: 'white',
      padding: '20px',
      position: 'relative',
    },
    pdfContainer: {
      overflowY: 'scroll',
      height: '100%',
      width: '100%',
    },
  };
  const pdfLink = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'; // Replace with your PDF URL

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="pdf-modal-title"
      aria-describedby="pdf-modal-description"
      sx={styles.modal}
    >
      <Box sx={styles.container}>
        <Typography id="pdf-modal-title" variant="h6" component="h2">
          PDF Document Viewer
        </Typography>
        <div style={styles.pdfContainer}>
          {pdfLink ? (

<EmbedPDF companyIdentifier="yourcompany">
<a href="https://cdn.simplepdf.eu/simple-pdf/assets/example_en.pdf">A PDF example</a>
</EmbedPDF>          ) : (
            <Typography color="error">No PDF link provided</Typography>
          )}
        </div>
        <Button
          style={{ position: 'absolute', top: 10, right: 10 }}
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default PDFModal;
