import React, { useEffect } from 'react';
import { Box, Button, Typography, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

const FileUpload = ({ label, file, onFileChange }) => {

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      onFileChange(uploadedFile); // Notify parent about the new file
    }
  };

  const handleFileRemove = () => {
    onFileChange(null); // Notify parent to remove the file
  };

  return (
    <Box>
      <Typography sx={{ marginBottom: 1 }}><b>{label}</b></Typography>

      {!file ? (
        <Button
          variant="contained"
          component="label"
        >
          Upload File
          <input
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      ) : (
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            value={file.name}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={handleFileRemove}>
                  <Close />
                </IconButton>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
