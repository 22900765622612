import React from "react";
import PreviewEmail from "../../screens/PreviewEmail";
import { Grid, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";




export const PreviewEmailModal = ({show, handleClose, companyName, link, buttonText, subtitle, customMessage, message, year, companyEmail, companyRep, questionCount, applicant}) => {


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '90vh',
        bgcolor: '#ffffff', // White background
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        paddingTop:2, 
        overflowY: 'auto',
      };

return (
    <Modal
    open={show}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalStyle}>
      <Grid container>
        <Grid xs={11} md={11} item>
        <Typography sx={{fontSize:18}} variant="button"><b>Preview Email</b></Typography>
       <br/>
        <Typography variant="p">This is a preview of the email that will be sent to the referencer.</Typography>
        </Grid>

        <Grid xs={1} md={1} item>
            <IconButton onClick={handleClose}><Close/></IconButton>
        </Grid>
        </Grid>
        <PreviewEmail companyName={companyName} companyRep={companyRep} companyEmail={companyEmail} link={link} buttonText={buttonText} subtitle={subtitle} customMessage={customMessage} message={message} year={year} questionCount={questionCount} applicant={applicant} />
        
        </Box></Modal>
)

}