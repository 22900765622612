import React, { useEffect, useState } from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Button, Grid, IconButton, Link, Paper, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Container } from "react-bootstrap";
import hdhrIcon from '../assets/hdhr-icon.png'
import appIcon from '../assets/images/rc-logo-dark.png'
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { API, link } from "../classes/config/controller";
import { formatDate } from "../classes/GlobalFunctions";
import ToolTipButton from "../components/ToolTipButton";
import { ContentCopy, Delete, Download, Email, ForwardToInboxRounded, Info, MailLock, Send, SendTwoTone } from "@mui/icons-material";
import { GenerateDocument } from "../classes/GenerateDocx";


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    
    
    ...(open && {
      
      
    }),
  }));

  function Copyright(props) {
    return (
      <div style={{textAlign:'center', marginTop:'25px'}}>
        <img src={hdhrIcon} width="75px"/>
        <br></br>
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
       
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com/">
          HelpDesk for HR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </div>
    );
  }


export const ArchivedReferenceDetails = ()=> {
const [data, setData] = useState(null);
const location = useLocation();
const { referenceCheckId, id } = location.state;  // Extract data from state
const [update, setUpdate] = useState(false)
const navigate = useNavigate();
useEffect(()=>{
const getData  = async ()=>{

    let response = await axios.post(API+'rc/get-archived-reference', {id:id, referenceCheckId:referenceCheckId})

    console.log(response.data)
 
   setData(response.data.referenceCheck)
}
getData();
   
},[])



const sendReference = async (i,link) => {

  
  if (window.confirm("Are you sure you want to send the reference email?")) {
    try {
      const response = await axios.post(API + 'rc/send-reference', {
        id,
        referenceCheckId,
        referenceObject:i,
        sender:data.company,
        applicant: data.applicant,
        link
      });

      console.log("Reference Sent successfully:", response.data);
      window.alert('Email has been sent.')
      setUpdate(!update)
    } catch (error) {
      console.error("Error deleting reference:", error);
    }
  }
};

const resendReference = async (i,link) => {

  
  if (window.confirm("Are you sure you want to resend the reference email?")) {
    try {
      const response = await axios.post(API + 'rc/resend-reference', {
        referenceObject:i,
        sender:data.company,
        applicant: data.applicant,
        link
      });

      console.log("Reference Sent successfully:", response.data);
      window.alert('Email has been sent.')
      setUpdate(!update)

    } catch (error) {
      console.error("Error deleting reference:", error);
    }
  }
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      alert('Link copied to clipboard!');
    })
    .catch(err => {
      console.error('Failed to copy the text to clipboard: ', err);
    });
};


    return(
        <>
        <AppBar position="absolute">
          <Toolbar
            sx={{
               
            backgroundColor:'#21828D',
           
       
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <img src={appIcon} height={50}/>
           
          </Toolbar>
        </AppBar>
       
          <Box
          component="main"
          sx={{
            backgroundColor: '#eef2f6',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding:10
          }}
        >
          <Toolbar>
          <Grid container>
              <Grid sx={{ mb: 2 }} item xs={12} md={6}>
                <Typography variant="button"><b>Applicant Name:</b></Typography>
                <Typography>{data?.applicant?.firstName} {data?.applicant?.lastName}</Typography>

              </Grid>

              <Grid sx={{ mb: 2 }} item xs={12} md={6}>
              <Typography variant="button"><b>Date Created:</b></Typography>
              <Typography>{formatDate(data?.dateCreated)}</Typography>
              </Grid>

              {/* <Grid item xs={12} md={6}>
              <Typography variant="button"><b>Placeholder:</b></Typography>
              <Typography>{'data?.company?.companyName'}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
              <Typography variant="button"><b>Placeholder:</b></Typography>
              <Typography>{'data?.company?.companyEmail'}</Typography>
              </Grid> */}

            </Grid>
          </Toolbar>
          <hr></hr>
          <Container  maxWidth="lg" sx={{ mb: 4 }}>

            <Paper sx={{padding:5, paddingTop:1, marginTop:2}}>
            <Table hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Referencers</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Completion Status</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody>
          {data?.references.map(i=>{
            return(
                <tr key={i.id}>
        
          <td style={{paddingTop:'15px'}}><span >{i.contactName}</span> </td>
          <td style={{paddingTop:'15px', textAlign:'center'}}>{<><Typography sx={{color:!i.sent ? 'orange': i.status === 'complete' ? 'green' :'orange'}} variant="button">{i.sent ? i.status:'Not Sent'}</Typography>{i.sent && i.status === 'seen' && (<Tooltip title={<Typography variant="caption">Referencer viewed the worksheet on {formatDate(i.lastDateSeen)}</Typography>}> <Info sx={{height:15, width:15, cursor:'alias'}} htmlColor="dodgerBlue"/></Tooltip>)}</>}</td>
          
          <td style={{textAlign:'center'}}>
     


           {i.status === 'complete' && (
            <>
            <ToolTipButton
            Title="View Responses"
            Icon={<Info htmlColor="dodgerBlue"/>}
            onClick={() => navigate('/viewer',{
              state: { 
                id:id,
                referenceCheckId: referenceCheckId,   
                referencerId: i.id,
                object:{
                  applicant:`${data?.applicant?.firstName} ${data?.applicant?.lastName}`,
                companyName: i.companyName,
                worksheet: i.worksheet,
                
                },
                archived:true,
                docName:`${data?.applicant?.firstName} ${data?.applicant?.lastName}'s reference from ${i.companyName}`
                   // Pass the reference check ID
              }})}            
            />

            <ToolTipButton
            Title="Download"
            Icon={<Download htmlColor="dodgerBlue"/>}
            onClick={()=>{
              const object = {
                applicant:`${data?.applicant?.firstName} ${data?.applicant?.lastName}`,
                companyName: i.companyName,
                worksheet: i.worksheet
                
              }
              console.log(object)
              GenerateDocument(object, `${data?.applicant?.firstName} ${data?.applicant?.lastName}'s reference from ${i.companyName}`)
            }}
            />

            </>
           )}
         

           </td>
        </tr>
            )
          })}
      </tbody>
    </Table>

            </Paper>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
  </>
    )
}