import React, { useState } from "react";
import {TextareaAutosize, Button, Modal, Box, Typography, TextField, Paper, IconButton, Grid, Icon } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { v4 as uuidv4 } from 'uuid';
import { Delete, Edit, EditOffOutlined, Menu } from "@mui/icons-material";
import ToolTipButton from "../ToolTipButton";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "axios";
import { API } from "../../classes/config/controller";
import { addWorksheet, user } from "../../classes/GlobalFunctions";

// Rename this component to avoid conflict
const SortableItemComponent = ({ id, question, onClickDelete}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 8,
    backgroundColor: 'white',
    border:'grey 1px solid',
    borderRadius:5
  };

  return (
    <Grid ref={setNodeRef} style={style} {...attributes} {...listeners} container>
    <Grid item xs={1} md={1}
     container
    alignItems="center" // Centers the delete button vertically
    justifyContent="center" // Centers the delete button horizontally

    >
    <Menu htmlColor="dodgerBlue"/>
    </Grid>
    <Grid
      container
      alignItems="center" // Centers the delete button vertically
    // Centers the delete button horizontally
    xs={11} md={11} item>
     <div >
      {question}
    </div>    
    </Grid>
    
    </Grid>
    
  );
};

export const WorksheetModal = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [worksheetQuestions, setWorksheetQuestions] = useState([]);
  const [worksheetTitle, setWorksheetTitle] = useState('')
  const [editing, setEditing] = useState(false);
  const [alignment, setAlignment] = React.useState('1');
  const [customQuestion, setCustomQuestion] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [questionNo, setQuestionNo] = React.useState('');
  const [working, setWorking] = React.useState(false);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDelete = (id) => {
    setWorksheetQuestions((prev) => prev.filter((item) => item.id !== id));
  };

  const handleToggleEdit = (id) => {
    setWorksheetQuestions((prev) =>
      prev.map((item) =>
        item.id === id
          ? {
              ...item,
              isEditing: !item.isEditing,
              question: item.isEditing ? item.editedQuestion : item.question,  // Save edited question when exiting edit mode
              editedQuestion: item.isEditing ? item.editedQuestion : item.question // Reset or initialize `editedQuestion`
            }
          : item
      )
    );
  };
  
  const handleQuestionEditChange = (id, newQuestion) => {
    setWorksheetQuestions((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, editedQuestion: newQuestion } : item
      )
    );
  };
  

  const getQuestions = async ()=>{
    setWorking(true)
    let prompt = `Please help me create a list of reference check questions tailored for the ${jobTitle} role. The questions should focus on assessing the candidate's key competencies, experience, and overall fit for the position. Please include questions that will help uncover any potential red flags and insights into the candidate's work ethic, teamwork, and problem-solving abilities. Please generate ${questionNo} questions. Return the questions in an object array in data only! The object should look like this: {"id": "some-id", "question":"question", "response":"empty string"}`;
     
              const response = await axios.post(API+'api/create-document',{request:prompt})
           if(response != 'error'){
              console.log(response.data)
      
            
              try {
                
                setWorksheetQuestions((prevData) => [...prevData, ...response.data]);               
              setWorking(false)

           
              } catch (error) {
                setWorking(false)

              console.error('Error parsing JSON response:', error);
              alert('Something went wrong please try again.')
              return;
              }
            
          //     let arr = JSON.parse(response.text)

             
      
            

           } else{
              alert('Something went wrong please try again.')
           }
          }

          const saveWorksheet = ()=>{
            if(worksheetTitle === ''){
              window.alert('Please add a title to the worksheet before saving.')
            } else {
              
            const worksheet = {
              id: uuidv4(),
              worksheetTitle:worksheetTitle,
              questions: worksheetQuestions
            }

            addWorksheet(user, worksheet)
        .then(message => {
            console.log('Success:', message);
            setWorksheetTitle('')
            setJobTitle('')
            setQuestionNo('')
            setWorksheetQuestions([]);
            props.handleClose();
            props.setUpdate(!props.update)
            setWorking(false)

            // You can update the UI or state here based on the success
        });

      }
          }



  const questions = [
    {
      id:0,
      label: `Custom Question`,
      value:`custom-82659`
    },
    {
        id:0,
        label: `What were the beginning and ending dates of the employees employment?`,
        value:`What were the beginning and ending dates of employees employment?`
      },
      {
        id:1,
        label:`What position did the employee hold in your organization?`,
        value:`What position did the employee hold in your organization?`
      }, 
      {
        id:2,
        label:`Is the employee eligible for rehire?`,
        value:`Is the employee eligible for rehire?`
      },
    {
        id:3,
        label:`Did the employee have any supervision responsibilities?`,
        value:`Did the employee have any supervision responsibilities?`
    },
    {
        id:4,
        label: 'How closely were you required to supervise the employee?',
        value:`How closely were you required to supervise the employee?`
    },
    {
        id:5,
        label: 'How willing was the employee to accept responsibility?',
        value:`How willing was the employee to accept responsibility?`
    },
    {
        id:6,
        label: 'What were some of the employee’s greatest strengths?',
        value:`What were some of the employee’s greatest strengths?`
    },
    {
        id:7,
        label: 'What were areas in which the employee needed more personal or professional development?',
        value:`What were areas in which the employee needed more personal or professional development?`
    },
    {
        id:8,
        label: 'What level of ownership would you say the employee took over the work assigned?',
        value:`What level of ownership would you say the employee took over the work assigned?`
    },
    {
        id:9,
        label: 'Could you rely on the employee to finish tasks in a timely manner?',
        value:`Could you rely on the employee to finish tasks in a timely manner?`
    },
    {
        id:10,
        label: 'Was the employee generally organized and prepared for tasks, deadlines, meetings, etc.?',
        value:`Was the employee generally organized and prepared for tasks, deadlines, meetings, etc.?`
    },
    {
        id:11,
        label: 'How well did the employee get along with other people?',
        value:`How well did the employee get along with other people?`
    },
    {
        id:12,
        label: 'Did the employee have issues with attendance?',
        value:`Did the employee have issues with attendance?`
    },
    {
        id:13,
        label: 'Why did the employee leave the company?',
        value:`Why did the employee leave the company?`
    },
    {
        id:14,
        label: 'What would you say is the employee’s level of ability with technology?',
        value:`What would you say is the employee’s level of ability with technology?`
    },
    {
        id:15,
        label: 'How did the employee handle conflict in the workplace?',
        value:`How did the employee handle conflict in the workplace?`
    },
    {
        id:16,
        label: 'Please tell me about a time when the employee was assigned a collaborative task or project. What were the strengths and weaknesses that the employee exhibited during the collaboration?',
        value:`Please tell me about a time when the employee was assigned a collaborative task or project. What were the strengths and weaknesses that the employee exhibited during the collaboration?`
    },
    {
        id:17,
        label: 'Did the employee learn new tasks or processes quickly or was there a struggle to grasp new concepts?',
        value:`Did the employee learn new tasks or processes quickly or was there a struggle to grasp new concepts?`
    },
    {
        id:18,
        label: 'What would you say was the greatest asset the employee offered to your organization?',
        value:`What would you say was the greatest asset the employee offered to your organization?`
    },
    {
        id:19,
        label: 'Was the employee written up or counseled for conduct issues during employment with your company?',
        value:`Was the employee written up or counseled for conduct issues during employment with your company?`
    },
    {
        id:20,
        label: 'Would you classify the employee as a goal setter, goal achiever, or goal-averse?',
        value:`Would you classify the employee as a goal setter, goal achiever, or goal-averse?`
    },
    {
        id:21,
        label: 'Tell me about a time when the employee failed at a task. How did they handle the failure and what was the result?',
        value:`Tell me about a time when the employee failed at a task. How did they handle the failure and what was the result?`
    },
    {
        id:22,
        label: 'When assigned any sort of company training would  you say the employee completed the courses in a timely manner or had to be encouraged to participate?',
        value:`When assigned any sort of company training would  you say the employee completed the courses in a timely manner or had to be encouraged to participate?`
    }
]

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setWorksheetQuestions((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh', // Set max height
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Enable vertical scrolling
  };

  const itemStyle = {
   
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 8,
    backgroundColor: 'white',
    border: '1px solid black',
  };
  return (
    <Modal
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create Reference Worksheet
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <Form.Label><b>Step One:  </b>Add Worksheet Title</Form.Label>
            <TextField label="Worksheet Title" variant="outlined" value={worksheetTitle} onChange={(e)=>setWorksheetTitle(e.target.value)} fullWidth />
            <hr />
            <Form.Label><b>Step Two:  </b>Add Questions</Form.Label>
            <br/>
            <ToggleButtonGroup
            fullWidth
            className="mb-3"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="1">Question Bank</ToggleButton>
              <ToggleButton value="2">Question Writer</ToggleButton>
            </ToggleButtonGroup>

            {alignment === '1' && (
              <>
              <Typography variant="h6"><b>Question Bank</b></Typography>
              <Box sx={{marginTop:2, marginBottom:2}}>
                <Typography>Select questions from the predefined question bank OR select <b>Custom Question</b> to write your own question.</Typography>
              </Box>
              <InputGroup>
       
            <Autocomplete
              sx={{ width: '80%' }}
              value={currentQuestion || null} // Ensures that the value is either a matching option or null
              onChange={(event, newValue) => {
                setCurrentQuestion(newValue);
              }}
              options={questions}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => option.value === value?.value} // Custom equality test
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Select a Question" 
                  variant="outlined" 
                />
              )}
            />
              <Button
              disabled={currentQuestion.value === 'custom-82659'}
                sx={{ width: '20%' }}
                onClick={() => setWorksheetQuestions(prev => [
                  ...prev,
                  { id: uuidv4(), question: currentQuestion.value, response: '' }
                ])}
                variant="contained"
              >
                Add Question
              </Button>
            </InputGroup>
           { currentQuestion.value === 'custom-82659' && (
            <>
            <TextField
            className="mt-4"
              label={'Enter Custom Question'}
              value={customQuestion}
              onChange={(e)=>setCustomQuestion(e.target.value)}
              fullWidth // Makes the text area full width
              multiline // Enables multiline (text area)
              rows={6} // Sets the number of visible text lines
              variant="outlined" // You can use "filled" or "standard" as well
            />
            <Button onClick={()=>{
               setWorksheetQuestions(prev => [
                ...prev,
                { id: uuidv4(), question: customQuestion, response: '' }
              ])
              setCurrentQuestion('');
              setCustomQuestion('')
            }} variant="contained" className="mt-3" fullWidth>Add Question</Button>
            </>
           )} 
            </>
      
            )}

            {alignment === '2' && (
              <>
                 <Typography variant="h6"><b>Question Writer</b></Typography>
              <Box sx={{marginTop:2, marginBottom:2}}>
                <Typography><b>Write questions using AI.</b> Enter the job title and question amount you would like to generate. <b>(Max 10 Questions)</b> Make sure and read through and edit the questions as needed.</Typography>
              </Box>
              <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <Typography><b>Job Title</b></Typography>
              <TextField 
              fullWidth
              onChange={(e)=>{setJobTitle(e.target.value)}}
              value={jobTitle}/>
              </Grid>

              <Grid xs={12} md={6} item>
                <Typography><b>Question Count</b></Typography>
                <TextField 
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    const value = Math.min(10, e.target.value); // Ensure the value does not exceed 10
                    setQuestionNo(value);
                  }}
                  value={questionNo}
                  inputProps={{ min: 1, max: 10 }} // Set the min and max values
                />
              </Grid>
              <Grid xs={12} md={12} item>
                <Button variant="contained" fullWidth onClick={getQuestions}>Generate Questions <Spinner size="sm" style={{display:working ? 'inline-block': 'none', marginLeft:10}}/></Button>
              </Grid>
              </Grid>
              </>
            )}
              <hr />
            <Typography variant="button"><b>Worksheet Preview</b></Typography>
            
            <br/>
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
              <SortableContext items={worksheetQuestions} strategy={verticalListSortingStrategy}>
                <Paper className="p-2" style={{ backgroundColor: 'white', marginTop: '16px' }}>
                <Grid className="p-2" spacing={2} container >
                <Grid item xs={10} md={6}>
                <Typography variant="h6">{worksheetTitle}</Typography>

                </Grid>
                <Grid item xs={2} md={6} >
                <Box sx={{textAlign:'end'}}>
                          {editing && <ToolTipButton onClick={()=>setEditing(false)} Title={<Typography>Back to question arrangement</Typography>}  Icon={<EditOffOutlined/>}/>}
                          {!editing && <ToolTipButton onClick={()=>setEditing(true)} Title={<Typography><b>Edit Questions</b><br/>Click here to remove questions you may not want.</Typography>} Icon={<Edit/>}/>}

                          </Box>
                </Grid>
                </Grid>
                  
                  { !editing && (
                    <Grid container >
                  {worksheetQuestions.map((item) => (
                
                  <SortableItemComponent key={item.id} id={item.id} question={item.question} onClickDelete={()=>handleDelete(item.id)} />
                  
                  ))}
                  </Grid>
                )}

{ editing && (
  <>
    {worksheetQuestions.map((item) => (
      <Grid
        key={item.id}
        sx={{ marginTop: 1, marginBottom: 1, padding: 1, border: 'grey 1px solid', borderRadius: 1 }}
        container
      >
        <Grid alignItems="center" justifyContent="center" item container sx={1} md={1}>
          <IconButton onClick={() => handleDelete(item.id)}><Delete htmlColor="tomato" /></IconButton>
        </Grid>
        <Grid container alignItems="center" item sx={10} md={10}>
          {item.isEditing ? (
            <TextField
              fullWidth
              value={item.editedQuestion || item.question}
              onChange={(e) => handleQuestionEditChange(item.id, e.target.value)}
              variant="outlined"
            />
          ) : (
            <Typography key={item.id}>{item.question}</Typography>
          )}
        </Grid>
        <Grid alignItems="center" justifyContent="center" item container sx={1} md={1}>
          <IconButton onClick={() => handleToggleEdit(item.id)}>
            {item.isEditing ? <EditOffOutlined htmlColor="green" /> : <Edit htmlColor="dodgerBlue" />}
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>
)}


                </Paper>
              </SortableContext>
            </DndContext>
          </Box>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={props.handleClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <Button variant="contained" onClick={saveWorksheet}>
            Save Changes <Spinner size="sm" style={{display:working ? 'inline-block': 'none', marginLeft:10}}/>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
