import { Box, Paper, Typography, Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../components/Cards/MainCard";
import SubCard from "../components/Cards/SubCard";
import MuiTypography from '@mui/material/Typography';
import { Delete, EditOutlined } from "@mui/icons-material";
import { WorksheetModal } from "../components/Modals/WorksheetModal";
import { deleteWorksheet, getOrCreateProfile, updateWorksheet, user } from "../classes/GlobalFunctions";
import { Spinner, Table } from "react-bootstrap";
import { EditWorksheetModal } from "../components/Modals/EditWorksheetModal";
import { SearchBar } from "../components/SearchBar";
import ToggleButtons from "../components/ToggleButton";


export const Worksheets = ()=>{
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState([])
    const [working, setWorking] = React.useState(false);
    const [loading, setLoading] = useState(true)
    const [searchBar, setSearchBar] = useState('');
    const [alignment, setAlignment] = React.useState('grid');

    const handleAlignment = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
    const [currentWorksheet, setCurrentWorksheet] = useState({
        id:'',
        worksheetTitle:'',
        questions:[],
      })
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (i) => {
        console.log(i)
        setShowEdit(true);
        setCurrentWorksheet(i)
    }
    useEffect(()=>{
        console.log(user)
        getOrCreateProfile(user)
        .then(profile => {
            console.log('Profile data:', profile);
            setData(profile)
            setLoading(false)
            // You can now use the profile data in your frontend application
        });
    },[update])

    function handleUpdateWorksheet() {
        setWorking(true)
    
        updateWorksheet(user, currentWorksheet)
            .then(message => {
                console.log('Success:', message);
                setCurrentWorksheet({
                    id:'',
                    worksheetTitle:'',
                    questions:[],
                  })
                  setUpdate(!update)
                  setShowEdit(false)
                  setWorking(false)

                // You can update the UI or state here based on the success
            });
    }

    const handleDeleteWorksheet = (id) => {
        if (window.confirm("Are you sure you want to delete this worksheet?")) {
            setWorking(true);
            deleteWorksheet(user, id) // Assuming `deleteWorksheet` takes the user and worksheet ID as parameters
                .then((message) => {
                    console.log("Worksheet deleted:", message);
                    setUpdate(!update); // Trigger UI update
                    setWorking(false);
                })
                .catch((error) => {
                    console.error("Error deleting worksheet:", error);
                    setWorking(false);
                });
        }
    };
    
    if(loading){
        return( <Box sx={{textAlign:'center'}}><Spinner/></Box>  )
    } else {
        
    return (
        <Box>
            <Box sx={{textAlign:'end', marginBottom:2}}>
            <Button onClick={handleShow} variant="contained" sx={{backgroundColor:'#EDAF00', fontWeight:'bold'}}>Create New Worksheet +</Button>
          
            </Box>
            
            <MainCard title={
                <Grid container>
                    <Grid xs={12} md={10} item>
                     <Typography sx={{fontSize:20}} variant="button">Worksheets</Typography>
                    </Grid>
                    <Grid xs={12} md={2} item>
                     <ToggleButtons
                     alignment={alignment}
                     handleAlignment={handleAlignment}
                     />
                    </Grid>
                </Grid>
            } >
            <Box sx={{marginBottom:2}}>
            <SearchBar
            label="Search Worksheet Name..."
            value={searchBar}
            onChange={(e)=> setSearchBar(e.target.value)}
            />
            </Box>
            {alignment === 'grid' && (
            <Grid spacing={2} container >
            {data.referenceChecker?.worksheets?.filter(i =>
          (i.worksheetTitle).toLowerCase().includes(searchBar.toLowerCase())
        ).map((i) => {
    return (
     

        <Grid item xs={12} sm={6} key={i.id}>
            <SubCard
                title={i.worksheetTitle}
                children={i.questions}
                secondary={
                    <>
                        <IconButton onClick={() => handleShowEdit(i)}><EditOutlined htmlColor="dodgerBlue"/></IconButton>
                        <IconButton onClick={() => handleDeleteWorksheet(i.id)}><Delete htmlColor="tomato"/></IconButton> {/* Delete button */}
                    </>
                }
            />
        </Grid>
  
    );
})}

      
         </Grid>
        )}
        { alignment === 'list' && (
              <Paper sx={{padding:1}}>

              <Table hover>
       <thead>
         <tr>
         
           <th><Typography variant="button"><b>Worksheet Title</b></Typography></th>
           <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
         </tr>
       </thead>
       <tbody>
           {data.referenceChecker?.worksheets?.filter(i =>
          (i.worksheetTitle).toLowerCase().includes(searchBar.toLowerCase())
        ).map(i=>{
             return(
                 <tr key={i.id}>
         
           <td style={{paddingTop:'15px'}}><span >{i.worksheetTitle}</span> </td>
           <td style={{textAlign:'center'}}>
                <IconButton onClick={() => handleShowEdit(i)}><EditOutlined htmlColor="dodgerBlue"/></IconButton>
                        <IconButton onClick={() => handleDeleteWorksheet(i.id)}><Delete htmlColor="tomato"/></IconButton> {/* Delete button */}
                   
            </td>
         </tr>
             )
           })}
       </tbody>
     </Table>
         </Paper>
        )}
             </MainCard>      
         <WorksheetModal update={update} setUpdate={setUpdate} handleClose={handleClose} show={show}/>
         <EditWorksheetModal working={working} setWorking={setWorking} handleUpdate={handleUpdateWorksheet} setCurrentWorksheet={setCurrentWorksheet} currentWorksheet={currentWorksheet} update={update} setUpdate={setUpdate} handleClose={handleCloseEdit} show={showEdit}/>

        </Box>

    )
}
}