import axios from 'axios';
import Cookies from "js-cookie";
const {API} = require('../classes/config/controller')
export const user = Cookies.get('user');
export function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

export async function getOrCreateProfile(email) {
    try {
        const response = await axios.post(API+'rc/get-or-create-profile', { email: email });
        console.log(response.data.message);
        return response.data.profile; // Returns the profile data
    } catch (error) {
        console.error('Error:', error.response ? error.response.data.message : error.message);
        // Handle error appropriately, e.g., show a notification to the user
    }
}

export async function addUserCompany(email, company) {
    try {
        const response = await axios.post(API+`rc/add-user-company`, { email: email, company: company });
        console.log(response.data.message);
        return response.data.message; // Returns the success message
    } catch (error) {
        console.error('Error:', error.response ? error.response.data.message : error.message);
        // Handle error appropriately, e.g., show a notification to the user
    }
}


export async function deleteUserCompany(email, companyId) {
    try {
        const response = await axios.post(API+`rc/delete-user-company`, { email: email, companyId: companyId });
        console.log(response.data.message);
        return response.data.message; // Returns the success message
    } catch (error) {
        console.error('Error:', error.response ? error.response.data.message : error.message);
        // Handle error appropriately, e.g., show a notification to the user
    }
}


export async function addWorksheet(email, worksheet) {
    try {
        const response = await axios.post(API+`rc/add-worksheet`, { email: email, worksheet: worksheet });
        console.log(response.data.message);
        return response.data.message; // Returns the success message
    } catch (error) {
        console.error('Error:', error.response ? error.response.data.message : error.message);
        // Handle error appropriately, e.g., show a notification to the user
    }
}

export async function updateWorksheet(email, worksheet) {
    try {
        const response = await axios.post(API + `rc/update-worksheet`, { email: email, worksheet: worksheet });
        console.log(response.data.message);
        return response.data.message; // Returns the success message
    } catch (error) {
        console.error('Error:', error.response ? error.response.data.message : error.message);
        // Handle error appropriately, e.g., show a notification to the user
    }
}

export async function deleteWorksheet(user, worksheetId) {
    try {
        const response = await axios.post(API + 'rc/delete-worksheet', {
            email: user,
            worksheetId: worksheetId,
        });
        return response.data.message; // Success message
    } catch (error) {
        console.error('Error deleting worksheet:', error.response ? error.response.data.message : error.message);
        throw error;
    }
}
