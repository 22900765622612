import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Divider, Grid, IconButton, TextField, Paper, Switch, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Form, InputGroup, Table } from 'react-bootstrap';
import ToolTipButton from '../ToolTipButton';
import { Add, ArrowDropDown, ArrowDropUp, Close, CloseOutlined, Delete, ExpandMore } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from "@mui/material/Autocomplete";
import '../../assets/css/style.css';
import FileUpload from '../File/FileUpload';
import PreviewEmail from '../../screens/PreviewEmail';
import { PreviewEmailModal } from './PreviewEmailModal';
import { API, link } from '../../classes/config/controller';
import axios from 'axios';
import { user } from '../../classes/GlobalFunctions';
import Loader from '../Loader';

export const ReferenceSetupModal = (props) => {
  const [show, setShow] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [worksheet, setWorksheet] = useState([]);
  
  const [referenceCheck, setReferenceCheck] = useState({
    id: uuidv4(),
    applicant: { firstName: '', lastName: '', authorization: '' },
    worksheet: worksheet,
    company: null,
    references: [],
    newCompany: { companyName: '', contactName: '', contactEmail: '' },
    customMessage: { addMessage: false, message: '' },
    dateCreated: new Date()
  });
  const [working, setWorking] = useState(false);


  const steps = [
    'Applicant Information',
    'Enter Applicant’s Reference Contact Information',
    'Your Company’s Information',
    'Choose the Worksheet',
    'Finalize and Send'
  ];

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
  };
  
  const [expanded, setExpanded] = useState(0); // State to manage accordion expansion
  const [sendImmediately, setSendImmediately] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEmail = () => setShowEmail(false);
  const handleShowEmail = () => setShowEmail(true);

  const handleFileChange = (file) => {
    setReferenceCheck((prevData) => ({
      ...prevData,
      applicant: {
        ...prevData.applicant,
        authorization: file,
      },
    }));
  };


  const updateReference = () => {
    setReferenceCheck((prevData) => ({
      ...prevData,
      references: [
        ...(prevData.references || []),
        {
          id: uuidv4(),
          companyName: companyName,
          contactName: contactName,
          contactEmail: contactEmail,
          sent: sendImmediately,
          status: 'unseen'
        }
      ]
    }));
    setCompanyName('');
    setContactEmail('');
    setContactName('');
    setSendImmediately(false)
    handleClose();
  };

  const handleSubmit = () => {
    setWorking(true)
    const missingFields = getMissingFields();

    if (missingFields.length > 0) {
      // Show missing fields in an alert
      window.alert(`Please complete the following fields:\n\n${missingFields.join('\n')}`);
    } else {
      // All fields are complete, call the save function
      saveReferenceCheck();
    }
  };

  const saveReferenceCheck = async () => {
    try {
      const updatedReferenceCheck = {
        ...referenceCheck,
        references: referenceCheck.references.map(ref => ({
          ...ref,
          sent: sendImmediately,
          worksheet: referenceCheck.worksheet
        })),
        dateCreated: new Date()
      };

      const fileFormData = new FormData();
      fileFormData.append('file', referenceCheck.applicant.authorization);
      fileFormData.append('id', props.id);
      fileFormData.append('send', sendImmediately);
      fileFormData.append('url', link);
      fileFormData.append('referenceCheck', JSON.stringify(updatedReferenceCheck));

      const response = await axios.post(API + 'rc/save-reference-checks', fileFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data.message);
      props.handleCloseAndSend();

      setReferenceCheck({
        id: uuidv4(),
        applicant: { firstName: '', lastName: '', authorization: '' },
        worksheet: [],
        company: null,
        references: [],
        newCompany: { companyName: '', contactName: '', contactEmail: '' },
        customMessage: { addMessage: false, message: '' },
        dateCreated: new Date()
      });
      setWorking(false)

    } catch (error) {
      console.error('Error saving reference check:', error.response?.data?.message || error.message);
      setWorking(false)
    }
  };

  const deleteReferenceById = (idToDelete) => {
    setReferenceCheck((prevData) => ({
      ...prevData,
      references: prevData.references.filter(item => item.id !== idToDelete)
    }));
  };

  const getMissingFields = () => {
    const missingFields = [];

    if (!referenceCheck.applicant.firstName) missingFields.push('Applicant First Name');
    if (!referenceCheck.applicant.lastName) missingFields.push('Applicant Last Name');
    if (!referenceCheck.references.length) missingFields.push('References');
    if (!referenceCheck.company) missingFields.push('Company Information');
    if (!referenceCheck.worksheet) missingFields.push('Worksheet');

    return missingFields;
  };

  const accordionStyle = {
    backgroundColor: '#f9f9f9',
    boxShadow: 'none',
    margin: '10px 0',
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh',
    bgcolor: '#ffffff',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    paddingTop: 2,
    overflowY: 'auto',
  };

  const buttonStyle = {
    mt: 1,
    mr: 1,
    color: '#ffffff',
    backgroundColor: '#007BFF',
    "&:hover": {
      backgroundColor: '#0056b3'
    }
  };

  const headingStyle = {
    color: '#333',
    fontWeight: 500,
    marginBottom: '8px',
  };

  const sectionPaperStyle = {
    backgroundColor: 'white',
    padding: 2,
  };

  return (
    <Modal
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Grid container>
          <Grid item xs={11} md={11}>
        <Typography id="modal-modal-title" variant="button" sx={{ color: 'black', fontWeight: 'bold', fontSize: 20 }}>
          Setup Reference Check
        </Typography>
        
        </Grid>
      
        <Grid item xs={1} md={1}>
          <IconButton onClick={props.handleClose}><CloseOutlined/></IconButton>
          </Grid>
        </Grid>
        
        <hr />
        {steps.map((label, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            sx={accordionStyle}
          >
            <AccordionSummary
            sx={{color: expanded === index ? '#21828D' : 'black' }}
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant='button' sx={{ fontSize: 16 }}><b>{label}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Render the content of each step */}
              {index === 0 && (
                <Grid component={Paper} sx={sectionPaperStyle} container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="button">Applicant First Name:</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={referenceCheck.applicant.firstName || ''}
                      onChange={(e) => {
                        setReferenceCheck((prevData) => ({
                          ...prevData,
                          applicant: {
                            ...prevData.applicant,
                            firstName: e.target.value,
                          },
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="button">Applicant Last Name:</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={referenceCheck.applicant.lastName || ''}
                      onChange={(e) =>
                        setReferenceCheck((prevData) => ({
                          ...prevData,
                          applicant: {
                            ...prevData.applicant,
                            lastName: e.target.value,
                          },
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={headingStyle}>
                      <b>Upload the Applicant’s Reference Check Form</b>
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      A sample form can be found in the Resources and Settings section of the app.
                    </Typography>
                    <FileUpload
                      file={referenceCheck.applicant.authorization}
                      onFileChange={handleFileChange}
                    />
                  </Grid>
                </Grid>
              )}

              {index === 1 && (
                <Grid component={Paper} sx={sectionPaperStyle} container spacing={1}>
                  <Grid item xs={11} md={11}>
                    <Typography variant="h6" sx={headingStyle}>Enter Applicant’s Reference Contact Information</Typography>
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <ToolTipButton onClick={handleShow} sx={{ backgroundColor: 'dodgerBlue', padding: 0.25, "&:hover": {
                      backgroundColor: "navy",
                      color: "red",
                    } }} Title="Add Reference" Icon={<Add htmlColor='white' />} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p">Click the <span style={{fontSize:18}}><b>+</b></span> button to start entering the referencers contact information. <b>Note:</b> You can enter multiple references to send at once.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table hover>
                      <thead>
                        <tr>
                          <th><Typography variant="button"><b>Company Name:</b></Typography></th>
                          <th><Typography variant="button"><b>Contact Name:</b></Typography></th>
                          <th><Typography variant="button"><b>Contact Email:</b></Typography></th>
                          <th style={{ textAlign: 'center' }}><Typography variant="button"><b>Actions</b></Typography></th>
                        </tr>
                      </thead>
                      <tbody>
                        {referenceCheck.references.map((i) => (
                          <tr key={i.id}>
                            <td>{i.companyName}</td>
                            <td>{i.contactName}</td>
                            <td>{i.contactEmail}</td>
                            <td style={{ textAlign: 'center', padding: 0 }}>
                              <ToolTipButton Title="Delete" Icon={<Delete htmlColor='tomato' />} onClick={() => deleteReferenceById(i.id)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Grid>
                </Grid>
              )}

              {index === 2 && (
                <Grid component={Paper} sx={sectionPaperStyle} container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={headingStyle}><b>Your Company's Information</b></Typography>
                    <Box sx={{marginTop:2, marginBottom:2}}>

                    <Typography variant="p">You can setup your company information in the <b>Resources and Settings</b> section of the app.</Typography>
                    </Box>
                    <Autocomplete
                      fullWidth
                      value={referenceCheck.company || null}
                      onChange={(event, newValue) => {
                        setReferenceCheck((prevData) => ({
                          ...prevData,
                          company: newValue,
                        }));
                      }}
                      options={props.companies}
                      getOptionLabel={(option) => option.companyName || ""}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Company"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {referenceCheck.company !== null && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography variant='button'>Company Name:</Typography>
                        <TextField
                          InputProps={{ readOnly: true }}
                          fullWidth variant='outlined' value={referenceCheck.company?.companyName} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant='button'>Contact Name:</Typography>
                        <TextField InputProps={{ readOnly: true }} fullWidth variant='outlined' value={referenceCheck.company?.companyRep} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography variant='button'>Contact Email:</Typography>
                        <TextField InputProps={{ readOnly: true }} fullWidth variant='outlined' value={referenceCheck.company?.companyEmail} />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}

              {index === 3 && (
                <Grid component={Paper} sx={sectionPaperStyle} container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={headingStyle}><b>Choose the Worksheet</b></Typography>
                    <Box sx={{marginTop:2, marginBottom:2}}>
                    <Typography variant="p">Select a worksheep you created in the <b>Create Worksheet</b> from the dropdown:</Typography>
                    </Box>
                    <InputGroup>
                      <Autocomplete
                        fullWidth
                        value={referenceCheck.worksheet || null}
                        onChange={(event, newValue) => {
                          setReferenceCheck((prevData) => ({
                            ...prevData,
                            worksheet: newValue
                          }))
                        }}
                        disableClearable
                        options={props.worksheets}
                        getOptionLabel={(option) => option.worksheetTitle || ""}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select a Worksheet"
                            variant="outlined"
                          />
                        )}
                      />
                    </InputGroup>
                    {referenceCheck.worksheet?.questions?.length > 0 && (<Paper sx={{ padding: 2 }}>
                      <Typography variant='button' sx={{ marginTop: 2, marginBottom: 2 }}><b>Questions Preview:</b></Typography>
                      {referenceCheck.worksheet.questions?.map((i, index) => { return (<Typography sx={{ marginTop: 2 }}>{index + 1}. {i.question}</Typography>) })}
                    </Paper>)}
                  </Grid>
                </Grid>
              )}

              {index === 4 && (
                <Grid component={Paper} sx={sectionPaperStyle} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Form.Check
                      checked={sendImmediately}
                      onChange={(e) => setSendImmediately(e.target.checked)}
                      type="switch"
                      id="custom-switch"
                      label={<Typography variant="subtitle2"><b>Send Email Immediately</b></Typography>}
                    />
                    <Typography sx={{ fontSize: 14 }} variant="caption">If you leave this switch off you can manually send the reference emails to each person in the details section of the applicant dashboard.</Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <hr></hr>
                    <Form.Check
                      checked={referenceCheck.customMessage.addMessage}
                      onChange={(e) => setReferenceCheck((prevData) => ({
                        ...prevData,
                        customMessage: {
                          ...prevData.customMessage,
                          addMessage: e.target.checked
                        }
                      }))}
                      type="switch"
                      id="custom-switch"
                      label={<Typography variant="subtitle2"><b>Add a custom message to the email that is sent.</b></Typography>}
                    />
                    <Typography sx={{ fontSize: 14 }} variant="caption">A standard email is sent to the referencer. You can view the email by clicking the button below. If you would like to add a custom message to the email to further customize the email you can do so here.</Typography>
                  </Grid>

                  {referenceCheck.customMessage.addMessage && (
                    <Grid item xs={12}>
                      <Typography variant='button'>Enter your custom message:</Typography>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        value={referenceCheck.customMessage.message}
                        onChange={(e) => setReferenceCheck((prevData) => ({
                          ...prevData,
                          customMessage: {
                            ...prevData.customMessage,
                            message: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleShowEmail}
                    >
                      Preview Email
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        sessionStorage.setItem('data', JSON.stringify(referenceCheck))
                        window.open('/#/preview', '_blank');
                      }}
                    >
                      Preview Reference Worksheet
                    </Button>
                  </Grid>
                  <Button onClick={handleSubmit} fullWidth variant='contained' sx={{marginTop:2}} >{sendImmediately ? 'Create and Send': 'Create'} References</Button>

                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
       <Loader open={working} title={<Typography variant='button' sx={{color:'white', fontSize:20}}>{sendImmediately ? "Creating and Sending " : "Sending "} References</Typography> }/>
        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Grid container>
              <Grid item xs={11} md={11}>
                <Typography sx={{ fontSize: 16 }} variant='button'><b>Enter Applicant’s Reference Contact Information</b></Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <IconButton onClick={handleClose}><Close /></IconButton>
              </Grid>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Grid item xs={12} md={12}>
                <Form.Label><b>Company Name:</b></Form.Label>
                <Form.Control
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  type='text' />
              </Grid>

              <Grid item xs={12} md={12}>
                <Form.Label><b>Contact Name:</b></Form.Label>
                <Form.Control
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  type='text' />
              </Grid>

              <Grid item xs={12} md={12}>
                <Form.Label><b>Contact Email:</b></Form.Label>
                <Form.Control
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  type='text' />
              </Grid>
              <Grid sx={{ textAlign: 'end' }} item xs={12} md={12}>
                <Button onClick={updateReference} className='mt-3' variant='contained'>Add Reference</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <PreviewEmailModal
          show={showEmail}
          handleClose={handleCloseEmail}
          companyName={referenceCheck.company?.companyName}
          companyRep={referenceCheck.company?.companyRep}
          companyEmail={referenceCheck.company?.companyEmail}
          customMessage={referenceCheck.customMessage.addMessage}
          message={referenceCheck.customMessage.message}
          year={new Date().getFullYear()}
          questionCount={referenceCheck?.worksheet?.questions?.length}
          applicant={referenceCheck.applicant.firstName + ' ' + referenceCheck.applicant.lastName}
        />
      </Box>
    </Modal>
  );
};
