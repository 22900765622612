import React, { useEffect, useState } from "react";
import MuiAppBar from '@mui/material/AppBar';
import { Button, Grid, IconButton, Link, Paper, TextField, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Container } from "react-bootstrap";
import hdhrIcon from '../assets/hdhr-icon.png';
import appIcon from '../assets/images/rc-logo-dark.png';
import axios from "axios";
import { API, link } from "../classes/config/controller";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { useTheme } from '@mui/material/styles';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...(open && {}),
}));

function Copyright(props) {
  return (
    <div style={{ textAlign: 'center', marginTop: '25px' }}>
      <img src={hdhrIcon} width="75px" />
      <br></br>
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com/">
          HelpDesk for HR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </div>
  );
}

export const ReferencerWorksheet = () => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState('');
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();  // Access the current location object
  const searchParams = new URLSearchParams(location.search);  // Get query params from the URL
  const id = searchParams.get('a');
  const referenceCheckId = searchParams.get('b');
  const referencerId = searchParams.get('c');

  const theme = useTheme(); // Get the current theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the device is mobile

  useEffect(() => {
    const fetchReferenceData = async () => {
      try {
        const response = await axios.post(`${API}rc/get-reference`, { id, referenceCheckId, referencerId });
        if (response.status === 200) {
          console.log('Reference data retrieved successfully:', response.data);
          setData(response.data);
          setLoading(false);
        } else {
          console.error('Error fetching reference data:', response.data.message);
          window.alert('Something went wrong, please try again later');
        }
      } catch (error) {
        console.error('Error during fetching reference:', error.response?.data?.message || error.message);
        window.alert('Something went wrong, please try again later');
      }
    };

    fetchReferenceData();
  }, [update]);

  const handleAnswerChange = (index, value) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.reference.worksheet.questions[index].response = value;
      return updatedData;
    });
    console.log(data.reference);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setLoadingTitle('Sending');

    try {
      const updatedReference = {
        ...data.reference,
        status: 'complete',
        lastDateSeen: new Date()
      };

      const emailObject = {
        companyName: data.reference.companyName,
        applicant: `${data?.applicant?.firstName} ${data?.applicant?.lastName}`,
        link: `${link}/web-viewer?a=${id}&b=${referenceCheckId}&c=${referencerId}`,
        senderEmail: data?.company?.companyEmail,
        senderName: data?.company?.companyRep.split(' ')[0],
        referencer: data.reference
      };

      const response = await axios.post(`${API}rc/update-reference`, {
        id,
        referenceCheckId,
        referencerId,
        updatedReference,
        emailObject
      });

      console.log('Reference updated successfully:', response.data);
      setUpdate(!update);
    } catch (error) {
      console.error('Error updating reference:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (<Loader title={<Typography className="mb-2" color="white"><b>{loadingTitle}</b></Typography>} open={true} />);
  } else {
    return (
      <>
        <AppBar position="absolute">
          <Toolbar
            sx={{
              backgroundColor: '#21828D',
              pr: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <img src={appIcon} height={isMobile ? 40 : 50} />
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: '#eef2f6',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: isMobile ? 2 : 10, // Adjust padding for mobile
            pt: 10 // Additional padding to compensate for the AppBar
          }}
        >
          <Toolbar>
            <Grid container spacing={isMobile ? 2 : 4}>
              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Applicant Name:</b></Typography>
                <Typography>{data?.applicant?.firstName} {data?.applicant?.lastName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Request sent to:</b></Typography>
                <Typography>{data.reference.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Reference requested by:</b></Typography>
                <Typography>{data?.company?.companyName}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="button"><b>Contact:</b></Typography>
                <Typography>{data.company.companyRep} - {data?.company?.companyEmail}</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <hr></hr>
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            {data.reference.status === 'complete' ? (
              <Paper sx={{ padding: 3, marginTop: 2 }}>
                <Typography>
                  Thank you for completing the Reference Check Worksheet. You have been sent a copy of your answers for your records. You can now close this page.
                </Typography>
              </Paper>
            ) : (
              <>
                <Typography sx={{ mb: 2 }}>
                  <b>Please answer the following questions and click Submit at the bottom of the page. Thank you in advance for your time and consideration.</b>
                </Typography>

                <Paper sx={{ padding: isMobile ? 2 : 5, paddingTop: 1, marginTop: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {data?.reference.worksheet?.questions?.map((i, index) => (
                        <Box className="mt-4" key={index}>
                          <Typography sx={{ mb: 1 }}><b>{index + 1}. {i.question}</b></Typography>
                          <TextField
                            multiline
                            rows={4}
                            fullWidth
                            defaultValue={i.response || ''}  // If there is an existing answer, display it
                            onChange={(e) => handleAnswerChange(index, e.target.value)}  // Track changes
                          />
                        </Box>
                      ))}
                    </Grid>

                    <Grid item xs={12}>
                      <Button onClick={handleSubmit} variant='contained' fullWidth={isMobile} sx={{ mt: 2 }}>Send Response</Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </>
    );
  }
};
