import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { CompanyInfo } from '../settings/CompanyInfo';
import { Spinner } from 'react-bootstrap';

export const CompanyModal = (props) => {

  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px', // Adjust the width as necessary
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <CompanyInfo setCompanyInfo={props.setCompanyInfo} companyInfo={props.currentData}/>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={props.handleClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <Button  variant="contained" onClick={props.handleData}>
            Save Changes <Spinner size='sm' style={{display:props.spinner ? 'inline-block':'none'}}/>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
