import React, { useEffect } from "react";


export const Redirect = ()=>{



useEffect(()=>{
    window.location.replace("http://www.helpdeskforhr.com/login/");

},[])
    return(<></>)
}