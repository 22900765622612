import { EditOffOutlined, EditOutlined, SaveAltOutlined, SaveOutlined } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Form } from "react-bootstrap";




export const CompanyInfo = ({companyInfo, editing, setCompanyInfo})=>{

    const handleInputChange = (fieldName, value) => {
        setCompanyInfo((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
        console.log(companyInfo)
      };

    return (
       <>
        <Grid className="p-2" spacing={2} container >
        <Grid item xs={10} md={6}>
        <Typography variant="h6">{editing ? 'Edit': 'New'} Company Information</Typography>

        </Grid>
        <Grid item xs={2} md={6} >
        <Box sx={{textAlign:'end'}}>
       
        </Box>
        </Grid>
        </Grid>
      
       <Grid className="p-2" spacing={2} container>
        <Grid item xs={12} md={12}>
            <Form.Label><b>Company Name:</b></Form.Label>
            <Form.Control 
            value={companyInfo.companyName} 
            type="text"
            onChange={(e)=>{handleInputChange('companyName', e.target.value)}}
            />
        </Grid>

        <Grid item xs={12} md={12}>
        <Form.Label><b>Full Name and Title of Recruiter/HR Rep:</b></Form.Label>
            <Form.Control
             value={companyInfo.companyRep} 
             onChange={(e)=>{handleInputChange('companyRep', e.target.value)}}
             type="text"/>
        </Grid>
        <Grid item xs={12} md={12}>
        <Form.Label><b>Email Address:</b></Form.Label>
        <Form.Control
             value={companyInfo.companyEmail} 
             onChange={(e)=>{handleInputChange('companyEmail', e.target.value)}}
             type="text"/>
        </Grid>
       </Grid>
       </>
    )
}