import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import pdfImg from '../../assets/images/icons/Pdf.png'
import docxImg from '../../assets/images/icons/docx.png'
import ToolTipButton from '../ToolTipButton';
import { Download } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { EmbedPDF } from '@simplepdf/react-embed-pdf';
import '../../assets/css/image-card.css'
export default function ImageCard({title, description, file, type, onClickView}) {
  return (
    <a href={file} target='_blank' download style={{ textDecoration: 'none' }}>
      <Card sx={{width: '300px', height: '300px', overflow: 'hidden' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="175"
          
          image={type === 'pdf' ? pdfImg :docxImg}
          alt={type}
        />
        <CardContent>
          <Typography gutterBottom variant="button" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
      <a href={file} download style={{ textDecoration: 'none' }}>
      <ToolTipButton Title={'Download Document'} Icon={<Download/>}/>
        </a>
       
      </CardActions> */}
    </Card>
    </a>
  );
}